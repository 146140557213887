import React from "react";
import moment from "moment";
import "../assets/css/OpeningHours.css";

export const OpeningHours = props => {
  const today = moment().format("dddd");
  return (
    <div class="business-hours">
      <h2 class="title">Opening Hours</h2>
      <ul class="list-unstyled opening-hours">
        <li className={today === "Monday" ? "today" : null}>
          Monday <span class="pull-right">{props.monday}</span>
        </li>
        <li className={today === "Tuesday" ? "today" : null}>
          Tuesday <span class="pull-right">{props.tuesday}</span>
        </li>
        <li className={today === "Wednesday" ? "today" : null}>
          Wednesday <span class="pull-right">{props.wednesday}</span>
        </li>
        <li className={today === "Thursday" ? "today" : null}>
          Thursday <span class="pull-right">{props.thursday}</span>
        </li>
        <li className={today === "Friday" ? "today" : null}>
          Friday <span class="pull-right">{props.friday}</span>
        </li>
        <li className={today === "Saturday" ? "today" : null}>
          Saturday <span class="pull-right">{props.saturday}</span>
        </li>
        <li className={today === "Sunday" ? "today" : null}>
          Sunday <span class="pull-right">{props.sunday}</span>
        </li>
      </ul>
    </div>
  );
};
