import React from "react";
import Helmet from "react-helmet";
import { ContactForm } from "../components/ContactForm";
import Maps from "../components/Maps";
import SEO from "../components/SEO";
import { OpeningHours } from "../components/OpeningHours";
import "../assets/css/App.css";
import "../assets/css/Form.css";
import "../assets/css/index.css";
import "../assets/css/Section.css";
import "../assets/css/Contact.css";

import Layout from "../components/Layout";

export default () => (
  <Layout>
    <div className="white-segment" id="contact-us">
      <SEO
        title="Contact"
        description="In need of commercial boiler services in London and Hertfordshire, commercial boiler maintenance, commercial heating services or domestic heating services? Get in contact with BBIS Heating"
        keywords="BBIS, Contact, Email Address, Phone Number"
      />
      <h1 class="heading-color">Contact Us</h1>
      <div class="row">
        <div className="col-sm-12 col-md-6 col-lg-6 c-p-r">
          <h2 class="title">Enquiries</h2>
          <ContactForm />
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 c-p-l">
          <h2 class="title">Find Us</h2>
          <Maps />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 c-p-r">
          <h2 class="title">Get in Touch</h2>

          <div className="row">
            <div className="col-1">
              <span className="fa fa-map-marker"></span>
            </div>
            <div className="col-11">
              <address>
                <strong>Hertfordshire Office:</strong>
                <br />
                Cuffley,
                <br />
                Potters Bar,
                <br />
                Hertfordshire,
                <br />
                EN6 4JR
              </address>
              <address>
                <strong>London Office:</strong>
                <br />
                152-160 City Road,
                <br />
                London,
                <br />
                EC1V 2NX
              </address>
            </div>
          </div>

          <div className="row">
            <div className="col-1">
              <span className="fa fa-phone"></span>
            </div>
            <div className="col-11">
              <a href="tel:+448002289800">0800 2289 800</a>
            </div>
            <div className="col-1">
              <span className="fa fa-phone"></span>
            </div>
            <div className="col-11">
              <a href="tel:+441707655800">01707 655800</a>
            </div>
          </div>
          <div className="row">
            <div className="col-1">
              <span className="fa fa-envelope"></span>
            </div>
            <div className="col-11">
              <a href="mailto:info@bbisheating.co.uk">info@bbisheating.co.uk</a>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 c-p-l">
          <OpeningHours
            monday="08:00 - 16:00"
            tuesday="08:00 - 16:00"
            wednesday="08:00 - 16:00"
            thursday="08:00 - 16:00"
            friday="08:00 - 16:00"
            saturday="08:00 - 16:00"
            sunday="08:00 - 16:00"
          />
          <p>24 Hour emergency call out</p>
        </div>
      </div>
    </div>
  </Layout>
);
