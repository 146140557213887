import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

class Maps extends Component {
  static defaultProps = {
    center: {
      lat: 51.56,
      lng: -0.11
    },
    zoom: 9
  };

  render() {
    return (
      <div style={{ height: "70vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyCU61AIFior0to5XyTrYhbj5AmRmAFZd1A"
          }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        ></GoogleMapReact>
      </div>
    );
  }
}

export default Maps;

/* "AIzaSyAUeFFrypEyaVQpIb2VM_K - ruWVkgf8zF0" */
